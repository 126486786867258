import { Sidebar } from "flowbite-react";
import {
  HiAdjustments,
  HiChartPie,
  HiChevronDoubleUp,
  HiQuestionMarkCircle,
  HiShoppingBag,
  HiTable,
  HiUser,
} from "react-icons/hi";
import { IoMdSettings } from "react-icons/io";
import { logout } from "../../auth/services/LogoutService/Logout.service";
import { setUser } from "../../auth/UserSlice";
import { useNavigate } from "react-router-dom";
import { User } from "../../auth/services/RegisterService/Register.types";
import { useAppDispatch } from "../../../store";

export const DashboardSidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    dispatch(setUser({} as User));
    window.location.href = "/";
  };

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden rounded bg-white py-4 px-3">
      <Sidebar aria-label="Dashboard Sidebar">
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item href="/dashboard" icon={HiChartPie}>
                  Pulpit główny
                </Sidebar.Item>
                <Sidebar.Collapse icon={HiShoppingBag} label="Faktury">
                  <Sidebar.Item href="/dashboard/invoices">
                    Wszystkie
                  </Sidebar.Item>
                  <Sidebar.Item href="/dashboard/invoices/create">
                    Nowa
                  </Sidebar.Item>
                  {/* <Sidebar.Item href="#">Sales</Sidebar.Item>
                                        <Sidebar.Item href="#">Refunds</Sidebar.Item>
                                        <Sidebar.Item href="#">Shipping</Sidebar.Item> */}
                </Sidebar.Collapse>
                <Sidebar.Collapse href="#" icon={HiUser} label="Konto">
                  <Sidebar.Item href="/dashboard/account" icon={IoMdSettings}>
                    Ustawienia
                  </Sidebar.Item>
                </Sidebar.Collapse>
                {/* <Sidebar.Item href="#" icon={HiShoppingBag}>
                                        Products
                                    </Sidebar.Item>
                                    <Sidebar.Item href="#" icon={HiArrowSmRight}>
                                        Sign In
                                    </Sidebar.Item> */}

                <Sidebar.Item
                  href="/dashboard/help"
                  icon={HiQuestionMarkCircle}
                >
                  Pomoc
                </Sidebar.Item>

                <Sidebar.Item href="#" icon={HiTable} onClick={handleLogOut}>
                  Wyloguj
                </Sidebar.Item>

                <Sidebar.Collapse icon={HiAdjustments} label="Admin">
                  <Sidebar.Item href="/dashboard/admin-logs">Logs</Sidebar.Item>
                  <Sidebar.Item href="">TODO</Sidebar.Item>
                  {/* <Sidebar.Item href="#">Sales</Sidebar.Item>
                                        <Sidebar.Item href="#">Refunds</Sidebar.Item>
                                        <Sidebar.Item href="#">Shipping</Sidebar.Item> */}
                </Sidebar.Collapse>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};
