import { Table } from "flowbite-react";
import { useEffect, useState } from "react";
import { AppError, request } from "../../../../utils/Api.util";
import FlashMessage from "../../../common/FlashMessage";
import useQueryParam from "../../../../utils/customHooks/useQueryParam";
import { HeaderSection } from "../../../layout/Public/HeaderSection/HeaderSection";
import LoadingSpinner from "../../../layout/Spinner/Spinner";

interface logType extends Record<string, string> {
  level: string;
  message: string;
  timestamp: string;
}

function printRemainingLogKeys(
  logObj: logType,
  exceptions: string[] = []
): string {
  // Filter the keys to exclude exceptions
  const filteredEntries = Object.entries(logObj).filter(
    ([key]) => !exceptions.includes(key)
  );

  // Build the content as a string
  const content = filteredEntries
    .map(([key, value]) => `${key}: ${JSON.stringify(value)}`)
    .join("\n"); // Join each line with a newline character

  // Return the content
  return content;
}

export const AdminLogs = () => {
  const [logs, setLogs] = useState<logType[]>([]);
  const [flashMessage, setFlashMessage] = useState("");
  const [logType, setLogType] = useState("");
  const [isFetching, setIsFetching] = useState(true);

  const logTypeQueryParam = useQueryParam("type") || "info";

  const fetchLogs = async (requestLogType: string) => {
    setIsFetching(true);
    const response = await request<logType[]>(
      "GET",
      `/logs?type=${requestLogType}`
    );
    setIsFetching(false);
    if (!response.success) {
      setFlashMessage("Error fetching logs");
      return;
    }
    return response.responseBody;
  };

  useEffect(() => {
    setLogType(logTypeQueryParam);
    if (!logType) {
      return;
    }
    fetchLogs(logType).then((logs) => {
      logs && setLogs(logs);
    });
  }, [logType]);

  return (
    <>
      <HeaderSection heading={logType + " logs"} />

      <div className="container mx-auto py-6 px-2">
        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            {flashMessage && (
              <FlashMessage error={new AppError(flashMessage)} />
            )}
            <div className="bg-white relative shadow-md sm:rounded-lg overflow-hidden">
              <div className="overflow-x-auto">
                <Table striped>
                  <Table.Head>
                    <Table.HeadCell>Timestamp</Table.HeadCell>
                    <Table.HeadCell>Level</Table.HeadCell>
                    <Table.HeadCell>Message</Table.HeadCell>
                    <Table.HeadCell>Data</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {logs.map((log, index) => (
                      <Table.Row
                        // className="bg-white dark:border-gray-700 dark:bg-gray-800"
                        className={log.level == "error" ? "!bg-red-200" : ""}
                        key={index}
                      >
                        <Table.Cell className="font-medium text-gray-900 dark:text-white">
                          {log.timestamp}
                        </Table.Cell>
                        <Table.Cell>{log.level}</Table.Cell>
                        <Table.Cell>{log.message}</Table.Cell>
                        <Table.Cell>
                          {printRemainingLogKeys(log, [
                            "level",
                            "timestamp",
                            "message",
                          ])}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
